import React, { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import Fuse from "fuse.js"
import classnames from "classnames"
import slug from "slug"

/* Import Global Hook(s) */
import { useGuidePage, useGalleriesPage, useGalleries, useProgramPage } from "~queries/hooks"


/* Import Global Component(s) */
import BlockContent from "~components/blockContent/blockContent"
import Footer from "~components/footer/footer"
import Page from "~components/page/page"
import PageHeader from "~components/page/components/pageHeader/pageHeader"
import PageArticle from "~components/page/components/pageArticle/pageArticle"
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"
import PageArticleLink from "~components/page/components/pageArticle/components/pageArticleLink/pageArticleLink"

/* Import Global Animation(s) */
import elementTransitionAnimation from "~animations/elementTransition"

/* Import Local Component(s) */
import Galleries from "~components/pages/galleries/components/galleries/galleries"
import Search from "~components/pages/galleries/components/search/search"

import MapZoom from '~components/pages/guide/mapZoom/mapZoom'
import PartnersRow from '~components/partners/partnersRow'

/* Import Local Style(s) */
import "./fair-guide.scss"

const sortGalleriesAlphabetically = (galleries = [], search = false) => {
  // seperate by all #, then letter groups
  const sortedGalleryGroups = "#abcdefghijklmnopqrstuvwxyz".split("")

  // generate object with letter groups
  const sortedGalleries = sortedGalleryGroups.reduce(
    (accumulator, galleryGroup) => {
      accumulator[galleryGroup] = []
      return accumulator
    },
    {}
  )

  // add galleries to respective gallery groups
  galleries.reduce((sortedGalleries, gallery) => {
    const leadingCharacter = search
      ? gallery.item.name[0].toLowerCase()
      : gallery.name[0].toLowerCase()
    if (isNaN(leadingCharacter) && !'+%$.*&!@#^()?<>,{}[]_|'.includes(leadingCharacter)) {
      sortedGalleries[leadingCharacter].push(search ? gallery.item : gallery)
    } else {
      sortedGalleries["#"].push(search ? gallery.item : gallery)
    }
    return sortedGalleries
  }, sortedGalleries)

  return sortedGalleries
}


const FairGuide = ({ location }) => {
	const pageTitle = 'Fair Guide'

	const { guideImage, guideImageMobile, zoomLevel, zoomLevelMobile, mapPDF, programPanels } = useGuidePage()
	// const { programPanels } = useProgramPage()
	const { activeYear } = useGalleriesPage()

	const [activeIsGrid, activeSetIsGrid] = useState(true)
	const [activeSearchTerm, activeSetSearchTerm] = useState()

	const showActiveYear = (activeYear && activeYear.year.length > 0) ? true : false
	const activeGalleries = showActiveYear ? useGalleries(activeYear.year) : []

	const [panel, setPanel] = useState(slug('galleries'))


	// set up search logic
	const activeFuse = new Fuse(activeGalleries, {
    keys: ["name", "boothID"],
    threshold: 0.2,
    minMatchCharLength: 1,
  })
  const activeGalleriesMatches = activeFuse.search(activeSearchTerm ? activeSearchTerm : "")

  const activeHandleChange = event => { activeSetSearchTerm(event.target.value) }

  const activeSortedGalleries = showActiveYear ? sortGalleriesAlphabetically(
    activeSearchTerm ? activeGalleriesMatches : activeGalleries,
    !!activeSearchTerm
  ) : []


  // zoom logic
  // console.log( guideImage, guideImageMobile, zoomLevel, zoomLevelMobile, mapPDF )


  // return page
	return(
		<Page title={pageTitle} location={location}>
      <PageHeader title={pageTitle} location={location} />
      <PageArticle title={pageTitle} className="fair-guide">

        <PageArticleSection>
          <div className="panel-selectors">

          <span className="mobile_galleries">
            <span className="col">
              <button
                className={classnames({ "is-active": panel === slug('galleries') })}
                onClick={() => {
                  setPanel(slug('galleries'))
                }}
              >Galleries</button>  
            </span>
            {mapPDF && mapPDF.asset && <PageArticleLink to={mapPDF.asset.url} external={true} copy={'Map PDF'} />}
          </span>
          <button
            className={classnames(['desktop_galleries', { "is-active": panel === slug('galleries') }])}
            onClick={() => {
              setPanel(slug('galleries'))
            }}
          >Galleries</button>
            {programPanels.map(({ title, _key }) => (
              <button
                className={classnames({ "is-active": panel === slug(title) })}
                onClick={() => {
                  setPanel(slug(title))
                }}
                key={_key}
              >
                {title}
              </button>
            ))}
          </div>

          <div className="guide_wrapper">
	          <div className="view_wrapper">
		          {showActiveYear && panel == 'galleries' ? (
		            <div className="active-year">
		              <Search placeholder="Search Gallery or Booth ID" handleChange={activeHandleChange} value={activeSearchTerm} />
		              <Galleries galleries={activeSortedGalleries} isGrid={false} />
		            </div>
		          ) : null }

		          <AnimatePresence initial={false} exitBeforeEnter>
		            {programPanels.map(({ title, _rawCopy, _key, partnersListed }) => {
		              return panel === slug(title) ? (
		                <motion.div
		                  className={classnames("panel", `${slug(title)}-panel`)}
		                  initial="initial"
		                  animate="animate"
		                  exit="exit"
		                  variants={elementTransitionAnimation.variants}
		                  key={_key}
		                >
		                  <BlockContent blocks={_rawCopy} />
                      {partnersListed.map(l => {
                        console.log(l)
                        if(l._rawBodyCopy){
                          return (<BlockContent blocks={l._rawBodyCopy} />)
                        }else{
                          return (<PartnersRow classes="" partners={l.partners} />)
                        }
                      })}

		                </motion.div>
		              ) : null
		            })}
		          </AnimatePresence>

	          </div>
	          <div className="map_wrapper">
	          	{mapPDF && mapPDF.asset && <PageArticleLink to={mapPDF.asset.url} external={true} copy={'Map PDF'} />}

	          	<MapZoom
	          			guideImage={guideImage}
									guideImageMobile={guideImageMobile}
									zoomLevel={zoomLevel}
									zoomLevelMobile={zoomLevelMobile}

	          	/>


	          </div>
          </div>
        </PageArticleSection>

      </PageArticle>
      <Footer />
    </Page>
  )


}

export default FairGuide